/*
Copyright 2017-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.
*/
@font-face {
  font-family: "blueprint-icons-16";
  src: url("./blueprint-icons-16.ttf?350ff8afe3450f80569b34d52370360b") format("truetype"), url("./blueprint-icons-16.eot?350ff8afe3450f80569b34d52370360b#iefix") format("embedded-opentype"), url("./blueprint-icons-16.woff2?350ff8afe3450f80569b34d52370360b") format("woff2"), url("./blueprint-icons-16.woff?350ff8afe3450f80569b34d52370360b") format("woff");
}
@font-face {
  font-family: "blueprint-icons-20";
  src: url("./blueprint-icons-20.ttf?9050e649762ac7aadef9d51447060a98") format("truetype"), url("./blueprint-icons-20.eot?9050e649762ac7aadef9d51447060a98#iefix") format("embedded-opentype"), url("./blueprint-icons-20.woff2?9050e649762ac7aadef9d51447060a98") format("woff2"), url("./blueprint-icons-20.woff?9050e649762ac7aadef9d51447060a98") format("woff");
}

/*# sourceMappingURL=blueprint-icons.css.map */